<template>
	<div>
		<div class="mb-4">
			<kr-form-i
				v-slot="{ field, errors }"
				v-model="data.model.password"
				name="password"
				:rules="rules('password')"
				tooltip-message="Password must be at least 12 characters and contain a combination of uppercase and lowercase letters, numbers and symbols."
			>
				<label for="password_input" class="form-label"> Password </label>
				<input
					id="password_input"
					:type="showPassword ? 'text' : 'password'"
					class="form-control position-relative"
					v-bind="field"
					:class="{ 'is-invalid': errors[0] }"
					@keyup="passwordStrength"
				/>

				<button
					class="
						btn
						cursor-pointer
						position-absolute
						top-33px
						end-33px
						p-0
						rounded
					"
					:aria-label="showPassword ? 'Hide Password' : 'Show Password'"
					@click="showPassword = !showPassword"
				>
					<span class="material-icons-outlined" aria-hidden="true">
						<template v-if="showPassword"> visibility_off </template>
						<template v-else> visibility </template>
					</span>
				</button>
			</kr-form-i>
		</div>
		<div>
			<kr-form-i
				v-slot="{ field, errors }"
				v-model="data.model.password_confirmation"
				name="password_confirmation"
				:rules="rules('password_confirmation')"
				tooltip-message="Please enter the same password details as the one entered above."
			>
				<label for="password_confirmation_input" class="form-label">
					Re-type Password
					<!-- <span
						:class="{ isMobile: helpers.layout.isMobile() }"
						tooltip="Please enter the same password details as the one entered above."
						class="material-icons-outlined"
						aria-label=" "
					>
						info
					</span> -->
				</label>
				<input
					id="password_confirmation_input"
					:type="showRetypePassword ? 'text' : 'password'"
					class="form-control position-relative"
					v-bind="field"
					:class="{ 'is-invalid': errors[0] }"
					@keyup="passwordStrength"
				/>

				<button
					class="
						btn
						cursor-pointer
						position-absolute
						top-33px
						end-33px
						p-0
						rounded
					"
					:aria-label="showRetypePassword ? 'Hide Password' : 'Show Password'"
					@click="showRetypePassword = !showRetypePassword"
				>
					<span class="material-icons-outlined" aria-hidden="true">
						<template v-if="showRetypePassword"> visibility_off </template>
						<template v-else> visibility </template>
					</span>
				</button>
			</kr-form-i>
		</div>
		<div class="passwordRequirements mt-2">
			<label
				>Password Strength:
				<span
					:class="{
						'text-danger': strength.value < 26,
						'text-warning': strength.value >= 26 && strength.value < 51,
						'text-info': strength.value >= 51 && strength.value < 100,
						'text-success': strength.value == 100,
					}"
					>{{ strength.label }}</span
				></label
			>
			<div class="progress">
				<div
					class="progress-bar"
					:class="{
						'bg-danger': strength.value < 26,
						'bg-warning': strength.value >= 26 && strength.value < 51,
						'bg-info': strength.value >= 51 && strength.value < 100,
						'bg-success': strength.value == 100,
					}"
					role="progressbar"
					:style="`width: ${strength.value}%`"
					:aria-valuenow="strength.value"
					aria-valuemin="0"
					aria-valuemax="100"
				></div>
			</div>

			<div class="mt-3">
				<label>Password Requirements</label>

				<div class="row m-0">
					<div v-if="requireLength" class="col-6 d-flex align-items-start">
						<span
							class="align-middle"
							:class="{
								'text-success material-icons': lengthFlag,
								'material-icons-outlined': !lengthFlag,
							}"
							:aria-label="lengthFlag ? 'Checked' : 'Uncheck'"
						>
							check_circle
						</span>
						<label
							:class="{
								'text-success': lengthFlag,
								'text-dark-grey': !lengthFlag,
							}"
						>
							At least {{ minChars }} characters long.
						</label>
					</div>
					<div v-if="requireLowercase" class="col-6 d-flex">
						<span
							class="align-middle"
							:class="{
								'text-success material-icons': lowerFlag,
								'material-icons-outlined': !lowerFlag,
							}"
							:aria-label="lowerFlag ? 'Checked' : 'Uncheck'"
						>
							check_circle
						</span>
						<label
							:class="{
								'text-success': lowerFlag,
								'text-dark-grey': !lowerFlag,
							}"
						>
							At least one lowercase letter.
						</label>
					</div>
					<div v-if="requireUppercase" class="col-6 d-flex">
						<span
							class="align-middle"
							:class="{
								'text-success material-icons': capitalFlag,
								'material-icons-outlined': !capitalFlag,
							}"
							:aria-label="capitalFlag ? 'Checked' : 'Uncheck'"
						>
							check_circle
						</span>
						<label
							:class="{
								'text-success': capitalFlag,
								'text-dark-grey': !capitalFlag,
							}"
						>
							At least one capital letter.
						</label>
					</div>
					<div class="col-6 d-flex">
						<span
							class="align-middle"
							:class="{
								'text-success material-icons': passwordMatchFlag,
								'material-icons-outlined': !passwordMatchFlag,
							}"
							:aria-label="passwordMatchFlag ? 'Checked' : 'Uncheck'"
						>
							check_circle
						</span>
						<label
							:class="{
								'text-success': passwordMatchFlag,
								'text-dark-grey': !passwordMatchFlag,
							}"
						>
							Passwords must match.
						</label>
					</div>
					<div v-if="requireSpecialChar" class="col-6 d-flex">
						<span
							class="align-middle"
							:class="{
								'text-success material-icons': symbolFlag,
								'material-icons-outlined': !symbolFlag,
							}"
							:aria-label="symbolFlag ? 'Checked' : 'Uncheck'"
						>
							check_circle
						</span>
						<label
							:class="{
								'text-success': symbolFlag,
								'text-dark-grey': !symbolFlag,
							}"
						>
							At least one symbol.
						</label>
					</div>
					<div v-if="requireNumber" class="col-6 d-flex">
						<span
							class="align-middle"
							:class="{
								'text-success material-icons': numberFlag,
								'material-icons-outlined': !numberFlag,
							}"
							:aria-label="numberFlag ? 'Checked' : 'Uncheck'"
						>
							check_circle
						</span>
						<label
							:class="{
								'text-success': numberFlag,
								'text-dark-grey': !numberFlag,
							}"
						>
							At least one number.
						</label>
					</div>
					<div v-if="requireNotUsername" class="col-6 d-flex">
						<span
							class="align-middle"
							:class="{
								'text-success material-icons': usernameFlag,
								'material-icons-outlined': !usernameFlag,
							}"
							:aria-label="usernameFlag ? 'Checked' : 'Uncheck'"
						>
							check_circle
						</span>
						<label
							:class="{
								'text-success': usernameFlag,
								'text-dark-grey': !usernameFlag,
							}"
						>
							Should not contain your name or email.
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { ref, reactive, toRefs, inject } from "vue";
export default {
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			},
			required: true,
		},
		rules: {
			type: Function,
			required: true,
		},
	},
	emits: ["canSend"],
	setup(props, context) {
		let { data } = toRefs(props);
		const helpers = inject("helpers");
		let usernameFlag = ref(1);
		let capitalFlag = ref(0);
		let lengthFlag = ref(0);
		let numberFlag = ref(0);
		let spaceFlag = ref(0);
		let lowerFlag = ref(0);
		let symbolFlag = ref(0);
		let passwordMatchFlag = ref(0);
		let strength = reactive({ label: "", value: 0 });
		const requireUppercase = true;
		const requireLowercase = true;
		const requireSpecialChar = true;
		const requireNumber = true;
		const requireSpace = false;
		const requireLength = true;
		const requireNotUsername = false;
		const minChars = 12;
		const maxChars = 30;

		const showPassword = ref(false);
		const showRetypePassword = ref(false);

		const passwordStrength = () => {
			capitalFlag.value = 0;
			lengthFlag.value = 0;
			numberFlag.value = 0;
			spaceFlag.value = 0;
			lowerFlag.value = 0;
			symbolFlag.value = 0;
			usernameFlag.value = 0;
			passwordMatchFlag.value = 0;

			var labels = ["", "Weak", "Fair", "Almost", "Pass"];
			var splChars = "*|,\":<>[]{}`';()@&$#%-!^_?/";
			//var str = document.getElementById('password').value;
			var str = data.value.model.password;
			var str2 = data.value.model.password_confirmation;
			if (str == str2 && str.length > 0) {
				passwordMatchFlag.value = 1;
			}
			if (str.length >= minChars) {
				lengthFlag.value = 1;
			}
			for (var i = 0; i < str.length; i++) {
				let c = str.charAt(i);

				if (c >= "0" && c <= "9") {
					numberFlag.value = 1;
				} else if (splChars.indexOf(c) != -1) {
					symbolFlag.value = 1;
				} else if (c === c.toUpperCase()) {
					capitalFlag.value = 1;
				} else if (c === c.toLowerCase()) {
					lowerFlag.value = 1;
				}
			}
			if (str.length > 0) {
				if (str.trim().length == str.length) {
					spaceFlag.value = 1;
				}
				/*if(!this.auth.user().email||!str.toLowerCase().includes(this.auth.user().email.toLowerCase())){
                    this.usernameFlag=1;
                }*/
			}
			let totalFlag = 0;
			let totalRequirements = 0;

			if (requireUppercase) {
				totalFlag += capitalFlag.value;
				totalRequirements++;
			}
			if (requireNumber) {
				totalFlag += numberFlag.value;
				totalRequirements++;
			}
			if (requireLength) {
				totalFlag += lengthFlag.value;
				totalRequirements++;
			}
			if (requireSpace) {
				totalFlag += spaceFlag.value;
				totalRequirements++;
			}
			if (requireLowercase) {
				totalFlag += lowerFlag.value;
				totalRequirements++;
			}
			if (requireSpecialChar) {
				totalFlag += symbolFlag.value;
				totalRequirements++;
			}
			if (requireNotUsername) {
				totalFlag += usernameFlag.value;
				totalRequirements++;
			}

			strength.value = (totalFlag / totalRequirements) * 100;

			if (strength.value == 0) {
				strength.label = labels[0];
			} else if (strength.value < 26) {
				strength.label = labels[1];
			} else if (strength.value < 51) {
				strength.label = labels[2];
			} else if (strength.value < 76) {
				strength.label = labels[3];
			} else if (strength.value == 100) {
				strength.label = labels[4];
			}

			totalFlag += passwordMatchFlag.value;
			totalRequirements++;

			//console.log("flag" + totalFlag + "requirements" + totalRequirements);

			if (totalFlag == totalRequirements) {
				context.emit("canSend", true);
			} else {
				context.emit("canSend", false);
			}
		};
		return {
			helpers,
			usernameFlag,
			capitalFlag,
			lengthFlag,
			numberFlag,
			spaceFlag,
			lowerFlag,
			symbolFlag,
			passwordMatchFlag,
			strength,
			requireUppercase,
			requireLowercase,
			requireSpecialChar,
			requireNumber,
			requireSpace,
			requireLength,
			requireNotUsername,
			minChars,
			maxChars,
			passwordStrength,
			showPassword,
			showRetypePassword,
		};
	},
};
</script>
