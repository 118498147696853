import axios from "axios";
//import KrAuth from "./../../components/auth";
//let auth = new KrAuth()
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = 60000;
axios.interceptors.request.use(
	function (config) {
		if (config.url.indexOf("://") > 0 || config.url.indexOf("//") === 0) {
			//absolute
		} else {
			//relative
			config.headers["Content-Type"] = "application/vnd.api+json";
			/*if (auth.getAuthHeader()) {
				config.headers["Authorization"] = auth.getAuthHeader();
			}*/
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
export default axios;
