import { createRouter, createWebHistory } from "vue-router";
import BlankLayout from "@/views/layout/BlankLayout";
const routes = [
	{
		path: "/",
		component: BlankLayout,
		// component: () => import("@/views/auth/signin.vue"),
		children: [
			{
				path: "errors/403",
				name: "auth.errors.403",
				meta: {
					title: "Error 403 | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/errors/403.vue"),
			},
			{
				path: "sso/:uuid",
				name: "auth.sso",
				meta: {
					title: "SSO | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/sso.vue"),
			},
			{
				path: "sso/:uuid",
				name: "auth.sso",
				meta: {
					title: "SSO | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/sso.vue"),
			},
			{
				path: "",
				name: "auth.login",
				meta: {
					title: "Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login.vue"),
			},
			{
				path: "logout",
				name: "auth.logout",
				meta: {
					title: "Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login.vue"),
			},
			{
				path: "switch",
				name: "auth.switch",
				meta: {
					title: "Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-email.vue"),
			},
			{
				path: "otp/:otp",
				name: "auth.lti",
				meta: {
					title: "Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-email.vue"),
			},
			{
				path: "express-sign-in/:courseID",
				name: "auth.login.express.course",
				meta: {
					title: "Express Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-express.vue"),
			},
			{
				path: "email-sign-in",
				name: "auth.login.email",
				meta: {
					title: "Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-email.vue"),
			},
			{
				path: "express-sign-in",
				name: "auth.login.express",
				meta: {
					title: "Express Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-express.vue"),
			},
			{
				path: "trouble-signing-in",
				name: "auth.login.trouble",
				meta: {
					title: "Trouble Signing In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-trouble.vue"),
			},
			{
				path: "forgot-password",
				name: "auth.password.email",
				meta: {
					title: "Forgot Password | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/password/email.vue"),
			},
			{
				path: "password/reset/:token",
				name: "auth.password.reset",
				meta: {
					title: "Reset Password | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/password/reset.vue"),
			},
			{
				path: "terms-of-use",
				name: "auth.terms-of-use",
				meta: {
					title: "Terms Of Use | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/terms-of-use.vue"),
			},
			{
				path: "magic",
				name: "auth.magic.email",
				meta: {
					title: "Magic Link | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/magic/email.vue"),
			},
			{
				path: "magic-happens",
				name: "auth.magic.login",
				meta: {
					title: "Magic Link | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-magic.vue"),
			},
			{
				path: ":courseID",
				name: "auth.login.express.course.cnamed",
				meta: {
					title: "Express Sign In | InteDashboard | TBL Makes Teams Work",
				},
				component: () => import("@/views/auth/login-express.vue"),
			},
		],
	},
	{ path: "/:pathMatch(.*)", redirect: "/" },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});
router.beforeEach((to) => {
	document.title = to.meta.title || "InteDashboard | TBL Makes Teams Work";
});

export default router;
