import Client from "./clients/AxiosClient";
//import { parseGetParameters } from "./helpers/helpers";
const resource = "/auth";
export default {
	verify(payload) {
		//global
		return Client.post(`${resource}/challenge`, payload);
	},
	/*Can be run while logged in, for superusers impersonation, hence baseURL can be null*/
	login(payload, baseUrl = "") {
		return Client.post(`${baseUrl}${resource}/sign-in`, payload);
	},
	logout(authorization, baseUrl) {
		return Client.get(`${baseUrl}/profile/sign-out`, {
			headers: { Authorization: `Bearer ${authorization}` },
		});
	},
	loginLti(otp, agreementDate, lti1p3, courseUuid, baseUrl) {
		return Client.post(`${baseUrl}/validate/${otp}`, {
			accepted: true,
			agreement_date: agreementDate,
			lti1p3: lti1p3,
			courseUuid: courseUuid,
		});
	},
	getCourse(urlCode) {
		//global
		return Client.get(`/express-sign-in/${urlCode}`);
	},
	getSections(courseUuid, baseUrl, headers) {
		return Client.post(
			`${baseUrl}/courses/${courseUuid}/roster`,
			{},
			{ headers: headers }
		);
	},
	registerCourse(payload, urlCode, baseUrl) {
		return Client.post(`${baseUrl}/express-sign-in/${urlCode}`, payload);
	},
	verifyCourse(payload, urlCode, baseUrl) {
		return Client.post(`${baseUrl}${resource}/challenge/${urlCode}`, payload);
	},
	verifyCourseEmail(payload, urlCode, baseUrl) {
		return Client.post(`${baseUrl}/express-sign-in/${urlCode}/email`, payload);
	},
	courseSelfAssign(payload, baseUrl, headers) {
		return Client.post(`${baseUrl}/student/self-assigned`, payload, {
			headers: headers,
		});
	},
	courseSkipSelfAssign(payload, baseUrl, headers) {
		return Client.post(`${baseUrl}/student/placements`, payload, {
			headers: headers,
		});
	},
	login2FA(payload, baseUrl) {
		return Client.post(`${baseUrl}/auth/sign-in-with-2fa`, payload);
	},
	resend2FA(payload, baseUrl) {
		return Client.post(`${baseUrl}/auth/resend-2fa`, payload);
	},
	password: {
		sendTemporaryPassword(payload, id) {
			return Client.post(`users/${id}/send-temporary-password`, payload);
		},
		/*Can be run while logged in, for superusers, hence baseURL can be null*/
		sendToken(payload) {
			return Client.post(`/auth/forgot-password`, payload);
		},
		reset(payload, baseUrl) {
			return Client.post(`${baseUrl}/password/reset`, payload);
		},
	},
	magic: {
		sendEmail(payload) {
			return Client.post(`/auth/challenge/magic`, payload);
		},
		login(url) {
			return Client.post(`${url}`);
		},
	},
	googleRedirect(urlCode) {
		return Client.get(`/sso/google/redirect?url_code=${urlCode}`);
	},
	microsoftRedirect(urlCode) {
		return Client.get(`/sso/microsoft/redirect?url_code=${urlCode}`);
	},
	switchRole(authorization, baseUrl) {
		return Client.post(
			`${baseUrl}/auth/switch-roles`,
			{},
			{ headers: { Authorization: `Bearer ${authorization}` } }
		);
	},
	/*sso.vue*/
	getUniversity(id, baseUrl) {
		return Client.get(`${baseUrl}/accounts/${id}/sso`);
	},
};
