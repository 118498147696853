<template>
	<form v-bind="$attrs" @submit.stop.prevent>
		<slot
			:meta="meta"
			:rules="getRule"
			:data="{ model: model }"
			:errors="errors"
			:isSubmitting="isSubmitting"
			:handleSubmit="handleSubmit"
		></slot>
	</form>
</template>

<script>
import { ref, toRefs, onMounted, readonly, watch } from "vue";
import { useForm } from "vee-validate";
export default {
	props: {
		options: {
			type: Object,
			default: function () {
				return { fields: {}, successCallback: null, failCallback: null };
			},
		},
	},
	setup(props, context) {
		const {
			errors,
			meta,
			isSubmitting,
			submitCount,
			validate,
			validateField,
			handleReset,
			resetForm,
			handleSubmit,
			setErrors,
			setFieldError,
			//setFieldValue,
			//setValues,
			setFieldTouched,
			setTouched,
		} = useForm();
		const { options } = toRefs(props);
		let fieldConfig = ref({});
		let model = ref({});
		let values = readonly(model);

		const setFieldValue = (key, value) => {
			if (key in model.value) {
				model.value[key] = value;
			}
		};
		const setValues = (data = {}) => {
			for (let key in data) {
				if (key in model.value) {
					model.value[key] = data[key];
				}
			}
		};
		const initialize = () => {
			for (let key in options.value.fields) {
				let field = options.value.fields[key];
				if (key in options.value.fields) {
					if (typeof field == "object") {
						model.value[key] =
							field.value !== undefined
								? field.value
								: field.default !== undefined
								? field.default
								: "";
						fieldConfig.value[key] = {
							default: field.default,
							rules: field.rules !== undefined ? field.rules : "",
							src: field.src !== undefined ? field.src : key,
						};
					} else {
						model.value[key] = field;
						fieldConfig.value[key] = {
							default: "",
							rules: "",
							src: key,
						};
					}
				}
			}
		};
		const getRule = (fieldName) => {
			if (fieldName in fieldConfig.value) {
				return fieldConfig.value[fieldName].rules;
			}
			return "";
		};
		const setModel = (data = null) => {
			for (var key in fieldConfig) {
				let field = fieldConfig[key];
				let src = field.src ? field.src : key;
				if (data != null && src in data) {
					setFieldValue(key, data[src]);
				} else {
					setFieldValue(key, fieldConfig[key].default);
				}
			}
			validate();
		};
		const reset = (data = null, options = null) => {
			model.value = {};
			initialize();
			setModel(data);
			resetForm();
		};
		watch(options, (currentValue, oldValue) => {
			reset();
		});
		initialize();

		onMounted(() => {});
		return {
			model,
			values,
			reset,
			setModel,
			getRule,
			fieldConfig,
			meta,
			errors,
			setFieldValue,
			setFieldError,
			setErrors,
			setValues,
			setFieldTouched,
			resetForm,
			validate,
			handleSubmit,
			isSubmitting,
		};
	},
};
</script>
