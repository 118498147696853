<template>
	<topbar v-if="!helpers.layout.isMobile()"></topbar>
	<div
		id="content"
		:class="{
			'bg-white': helpers.layout.isMobile(),
			'content-isMobile': helpers.layout.isMobile(),
		}"
	>
		<div :class="{ 'layout-container': !helpers.layout.isMobile() }">
			<div :class="{ 'layout-container-fluid': !helpers.layout.isMobile() }">
				<!-- <div
					class="
						alert alert-warning
						d-flex
						justify-content-between
						align-items-center
						sticky-top
					"
					role="alert"
					style="top: 72px"
				>
					<div class="d-flex justify-content-between align-items-center w-100">
						<div class="d-flex">
							<span class="material-icons-outlined me-2"> report_problem </span>
							<span
								class="cursor-pointer"
								data-bs-toggle="modal"
								data-bs-target="#viewInfo"
							>
								Click here for an update on recent system issues.
							</span>
						</div>

						<div
							class="cursor-pointer fs-4 lh-1"
							data-bs-dismiss="alert"
							aria-label="Close"
							@click="warningAlert = !warningAlert"
						>
							&times;
						</div>
					</div>
				</div> -->
				<div
					class="bg-white"
					:class="{
						'box-shadow': !helpers.layout.isMobile(),
						noAlertSign: !warningAlert,
					}"
				>
					<router-view />
				</div>
			</div>
		</div>
	</div>
	<div
		id="viewInfo"
		class="modal fade"
		data-bs-backdrop="static"
		data-bs-keyboard="false"
		role="dialog"
		tabindex="-1"
		:class="{ isMobileModal: helpers.layout.isMobile() }"
	>
		<div
			class="modal-dialog"
			:class="{
				'modal-dialog-scrollable': helpers.layout.isMobile(),
				'modal-dialog-centered': !helpers.layout.isMobile(),
				'modal-dialog-flex-end': helpers.layout.isMobile(),
			}"
		>
			<div class="modal-content">
				<div class="modal-header border-0">
					<button
						class="btn btn-outline-light iconOnly"
						data-bs-dismiss="modal"
						aria-label="close modal"
					>
						<span class="material-icons-outlined" aria-hidden="true">
							arrow_back
						</span>
					</button>
				</div>
				<div class="modal-body announcement-modal">
					<b>Status Update:</b>
					<p class="mb-2">
						Related to answer selection and activity submission on IRAT & TRAT's
					</p>

					<b>Issue/s</b>
					<p class="mb-2">
						Recently there were some incidents related to learners experiencing
						errors during the IRAT and TRAT activity process. These errors
						caused abnormal usage behaviour, intermittent time outs, and login
						issues.
					</p>

					<b>Action/s Taken</b>
					<p class="mb-2">
						We have disabled the function/s that kicks learners out of
						activities. This means that when the IRAT/TRAT timer reaches zero or
						timeout, learners will NOT be kicked out or log out; instead, they
						will still be allowed to continue with the activities. This will
						allow the teacher to manually manage the time taken provided to the
						learners to complete. This action will minimise the inconvenience of
						the “Issue/s” stated.
					</p>

					<b>What’s Next</b>
					<p class="mb-2">
						We have identified and rectifying the issue/s with high priority
						assigned to it.
					</p>

					<b>Timeline Recovery</b>
					<p>
						We are expecting that the issue/s to be solved within the next 72
						hours; or a little more time is anticipated depending on the
						server/coding restriction. Meanwhile, please
						<a href="mailto:support@intedashboard.com">email</a> us if you need
						assistance and we will get back to you as soon as we possibly can!
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { inject, onMounted, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
	components: {
		topbar: require(`./views/layout/partials/topbar.vue`).default,
	},
	setup() {
		const emitter = inject("emitter");
		const auth = inject("auth");
		const router = useRouter();
		const helpers = inject("helpers");
		// const warningAlert = ref(true);

		onMounted(() => {
			let url = "";
			emitter.on("system-login-successful", () => {
				url = `${auth.credential().appUrl}?access_token=${
					auth.credential().accessToken
				}&api_url=${auth.credential().apiUrl}`;
				window.location.href = url;
			});
			emitter.on("system-logout-successful", () => {
				router.push({ name: "auth.login" });
			});
		});

		return {
			helpers,
			// warningAlert,
		};
	},
};
</script>

<style lang="scss" scoped>
#content {
	width: 100%;
	min-height: 100%;
	background: rgba(219, 234, 254, 0.16);
	padding-top: 72px;

	&.content-isMobile {
		padding-top: 56px;
	}

	.box-shadow {
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
		height: 100%;
		max-height: 100%;
		// max-height: 613px;
		// overflow: auto;
		scroll-behavior: smooth;

		@media only screen and (min-device-width: 481px) and (orientation: portrait) {
			height: auto;
		}
	}

	.layout-container,
	.layout-container-fluid {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-right: var(--bs-gutter-x, 0.75rem);
		padding-left: var(--bs-gutter-x, 0.75rem);
	}

	.layout-container {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	// .layout-container-fluid {
	// 	height: calc(100vh - 72px - 24px - 24px);
	// }

	@media (min-width: 768px) {
		.layout-container {
			max-width: 768px;
		}
	}
}
</style>
