import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, emitter } from "./store";

import _ from "lodash";

import "./vendor/styles/bootstrap/custom.scss";
import "./vendor/styles/bootstrap/button.scss";
import "./vendor/styles/bootstrap/font.scss";
import "./vendor/styles/bootstrap/form.scss";
import "./vendor/styles/bootstrap/tooltip.scss";
import "./vendor/styles/bootstrap/modal.scss";
import "bootstrap";

import directives from "./directives/index";

// using ES6 modules

import { Repositories } from "./repositories/RepositoryFactory";
import { KrForm, KrFormInput, pinCode } from "./components/forms/index";
import { Helpers } from "./components/helpers/global/index";
import KrAuth from "./components/auth/index";
const app = createApp(App);

app.component("KrForm", KrForm);
app.component("KrFormI", KrFormInput);

app.component("PinCode", pinCode);

app.provide("$api", Repositories);
app.provide("_", _);
app.provide("auth", new KrAuth());
app.provide("emitter", emitter);
app.provide("helpers", Helpers);

directives(app);

app.use(store).use(router).mount("#app");
