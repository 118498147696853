<template>
	<div class="mb-3 d-flex align-items-center justify-content-between">
		<input
			v-for="(cell, idx) in cells"
			:key="cell.key"
			:ref="(el) => (inputs[idx] = el)"
			v-model.trim="cell.value"
			class="vue-pincode-input"
			maxlength="1"
			@focus="focusedCell = idx"
			@keydown.delete="onCellErase(idx, $event)"
			@keydown="onKeyDown"
			@change="focusNextCell()"
			@paste="onPaste"
		/>
	</div>
</template>

<script>
/*Port from https://github.com/Seokky/vue-pincode-input*/
import { ref, onBeforeUpdate, watch, computed } from "vue";
export default {
	props: {
		modelValue: {
			type: String,
			default: "",
		},
	},
	emits: ["update:modelValue", "change"],
	setup(props, context) {
		const inputs = ref([]);
		let cells = ref([]);
		var focusedCell = ref(0);
		let watchers = ref({});
		var i = 0;
		const value = computed(() => {
			return cells.value.reduce((pin, cell) => pin + cell.value, "");
		});
		const onCellChanged = function (idx, newVal, oldVal) {
			if (newVal.value.length != 0) {
				focusNextCell();
			}
		};
		const setCellWatcher = function (idx) {
			const watchingProperty = `cells.${idx}.value`;
			watchers.value[watchingProperty] = watch(
				cells.value[i],
				(newVal, oldVal) => onCellChanged(idx, newVal, oldVal)
			);
		};
		watch(value, () => {
			context.emit("update:modelValue", value);
			context.emit("change", value);
		});
		for (i = 0; i < 6; i++) {
			cells.value.push({ key: `vue-pincode-${i}`, value: "" });
			setCellWatcher(i);
		}

		const onCellErase = function (idx, e) {
			const isThisCellFilled = cells.value[idx].value.length;
			if (!isThisCellFilled) {
				focusPreviousCell();
				e.preventDefault();
			}
		};
		const focusCellByIndex = function (idx) {
			const el = inputs.value[idx];
			el.focus();
			el.select();
			focusedCell.value = idx;
		};
		const focusPreviousCell = function () {
			if (!focusedCell.value) return;
			focusCellByIndex(focusedCell.value - 1);
		};
		const focusNextCell = function () {
			if (focusedCell.value === 5) return;
			focusCellByIndex(focusedCell.value + 1);
		};
		const onKeyDown = function (e) {
			switch (e.keyCode) {
				case 37:
					focusPreviousCell();
					break;
				case 39:
					focusNextCell();
					break;
				default:
					break;
			}
		};
		const onPaste = function (e) {
			let pasted = e.clipboardData.getData("text").trim();
			for (i = 0; i < pasted.length && i < 6; i++) {
				cells.value[i].value = pasted[i];
			}
			return true;
		};
		onBeforeUpdate(() => {
			inputs.value = [];
		});
		return {
			focusedCell,
			onCellErase,
			onKeyDown,
			inputs,
			focusNextCell,
			cells,
			watchers,
			onPaste,
		};
	},
};
</script>

<style lang="scss" scoped>
.vue-pincode-input {
	outline: none;
	margin-left: 4px;
	margin-right: 4px;
	padding: 6px;
	width: 15%;
	text-align: center;
	font-size: 28px;
	background: #fff;
	border: none;
	border-radius: 4px;
	line-height: 0.8;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
	border: 1px solid rgba(0, 0, 0, 0.2);

	&:nth-child(3n + 3) {
		margin-right: 10px;
	}

	&:nth-child(4) {
		margin-left: 10px;
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&:focus {
		border: 1px solid #3b82f6;
		box-shadow: none;
	}

	&:focus-visible {
		border: 1px solid #3b82f6;
		outline: 4px solid #1941a6;
		outline-offset: 3px;
		box-shadow: 0px 0px 0px 4px rgb(255, 255, 255);
	}
}
</style>
