import { createStore } from "vuex";
import mitt from "mitt";
const emitter = mitt();

var appId = `cognalearn.auth.${process.env.VUE_APP_ENV}.client`;
let store = createStore({
	state: {
		client: {
			auth: {},
			expressSignInUrls: [],
		},
	},
	mutations: {
		auth(state, data) {
			state.client.auth = data;
			localStorage.setItem(appId, JSON.stringify(state.client));
		},
		expressSignInUrls(state, data) {
			state.client.expressSignInUrls = data;
			localStorage.setItem(appId, JSON.stringify(state.client));
		},
		addExpressSignInUrl(state, data) {
			//data is {http:'',code:''}
			if (state.client.expressSignInUrls.length == 5) {
				state.client.expressSignInUrls.pop();
			}
			for (var i = 0; i < state.client.expressSignInUrls.length; i++) {
				if (state.client.expressSignInUrls[i].urlCode == data.urlCode) {
					return;
				}
			}
			state.client.expressSignInUrls.push(data);
			localStorage.setItem(appId, JSON.stringify(state.client));
		},
	},
	actions: {
		logout(context) {
			context.commit("auth", {});
			//localStorage.removeItem(appId);
			emitter.emit("system-logout-successful");
		},
		login({ commit }, data) {
			if (process.env.VUE_APP_ENV == "local") {
				data.auth.appUrl = process.env.VUE_APP_V3_APP_URL;
			}

			commit("auth", data.auth);
			emitter.emit("system-login-successful", data.options);
		},
		addExpressSignInUrl({ commit }, data) {
			commit("addExpressSignInUrl", data);
		},
	},
	modules: {},
	getters: {
		isLoggedIn: (state) => {
			if (state.client.auth.accessToken != undefined) return true;
			return false;
		},
		auth: (state) => {
			return state.client.auth;
		},
		expressSignInUrls(state) {
			return state.client.expressSignInUrls;
		},
	},
});
if (localStorage.getItem(appId) != null) {
	var client = JSON.parse(localStorage.getItem(appId));
	store.commit("auth", client.auth);
	if (client.expressSignInUrls) {
		store.commit("expressSignInUrls", client.expressSignInUrls);
	}
}
export { store, emitter };
