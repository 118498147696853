<template>
	<Field
		:ref="
			(el) => {
				if (el) field = el;
			}
		"
		v-bind="$attrs"
		v-slot="{ field, meta, errors }"
	>
		<div class="w-100 input-container">
			<slot :field="field" :meta="meta" :errors="errors"> </slot>
			<div
				v-if="showTooltip && tooltipMessage"
				class="mt-2 input-tooltip"
				:class="tooltipClasses"
				aria-live="assertive"
			>
				<span class="input-tooltip-arrow"></span>
				{{ tooltipMessage }}
			</div>

			<div
				v-if="errors[0] && errors[0] != 'show outline only'"
				:id="field.name + '_error'"
				class="invalid-feedback"
				role="alert"
				aria-atomic="true"
			>
				<div class="d-flex align-items-center">
					<span class="material-icons-outlined" aria-hidden="true">
						error_outline
					</span>
					<span> {{ errors[0] }} </span>
				</div>
			</div>
		</div>
	</Field>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { Field } from "vee-validate";
export default {
	components: {
		Field,
	},
	props: {
		tooltipMessage: String,
		position: {
			type: String,
			default: "bottom",
		},
	},
	setup(props, context) {
		const field = ref(null);

		const showTooltip = ref(false);

		const show = () => {
			showTooltip.value = true;
		};

		const hide = () => {
			showTooltip.value = false;
		};

		const tooltipClasses = computed(() => ({
			[`tooltip--${props.position}`]: true,
		}));

		onMounted(() => {
			const inputElement = document.getElementById(field.value.name + "_input");
			if (inputElement) {
				inputElement.addEventListener("focus", show);
				inputElement.addEventListener("blur", hide);
			}
		});

		onBeforeUnmount(() => {
			const inputElement = document.getElementById(field.value.name + "_input");
			if (inputElement) {
				inputElement.removeEventListener("focus", show);
				inputElement.removeEventListener("blur", hide);
			}
		});

		return {
			field,
			showTooltip,
			tooltipClasses,
		};
	},
};
</script>
