import { store } from "@/store";
export default class KrAuth {
	constructor(options) {
		this.store = store;
	}
	login(data, options = {}) {
		var auth = {
			accessToken: data.data.token,
			serverTimeOnLogin: data.data.currentTime,
			apiUrl: data.data.api_url,
			appUrl: data.data.app_url,
			authUrl: process.env.VUE_AUTH_URL,
		};
		var push = {
			auth: auth,
		};
		this.store.dispatch("login", push);
	}
	addExpressSignInUrl(data) {
		this.store.dispatch("addExpressSignInUrl", data);
	}
	logout() {
		this.store.dispatch("logout");
	}
	isLoggedIn() {
		return this.store.getters.isLoggedIn;
	}
	credential() {
		return this.store.getters.auth;
	}
}
