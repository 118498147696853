<template>
	<nav class="navbar navbar-expand-lg navbar-light bg-white p-0 fixed-top">
		<div class="topbar-container">
			<div class="topbar-container-fluid d-flex justify-content-between">
				<a
					class="navbar-brand p-0 d-flex align-items-center"
					href="https://www.intedashboard.com/"
				>
					<img src="/img/logo.png" alt="Intedashboard Logo" />
				</a>
			</div>
		</div>
	</nav>
</template>

<style lang="scss" scoped>
.topbar-container,
.topbar-container-fluid {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
}

.topbar-container-fluid {
	height: 72px;
}

.navbar {
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}

.navbar-brand {
	img {
		width: 190px;
		height: auto;
	}
}

@media (min-width: 768px) {
	.topbar-container {
		max-width: 768px;
	}
}
</style>
