import { defineRule } from "vee-validate";
import { required, email, min, max, confirmed } from "@vee-validate/rules";
const KrForm = require("./components/kr-form.vue").default;
const KrFormInput = require("./components/kr-form-i.vue").default;
const KrChangePassword = require("./components/kr-change-password.vue").default;
const pinCode = require("./components/pin-code.vue").default;
defineRule("required", required);
defineRule("email", email);
defineRule("min", min);
defineRule("max", max);
defineRule("confirmed", confirmed);

defineRule("email", (value) => {
	if (!value) {
		return "Please enter your email.";
	}

	return true;
});

defineRule("password", (value) => {
	if (!value) {
		return "Please enter your password!";
	}

	return true;
});

defineRule("password_confirmation", (value) => {
	if (!value) {
		return "Password does not match!";
	}

	return true;
});

defineRule("courseID", (value) => {
	if (!value) {
		return "Invalid Course ID!";
	}

	return true;
});

defineRule("expressName", (value) => {
	if (!value) {
		return "Please enter your name!";
	}

	return true;
});

defineRule("secretPin", (value) => {
	if (!value) {
		return "Please enter a Secret PIN!";
	}

	return true;
});

export { KrForm, KrFormInput, pinCode };
